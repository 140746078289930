import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCorsRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import BranchForm from './components/Form';
import Items from './components/Items';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

const s3 = new AWS.S3({ params: { Bucket: 'publicslozt' } });

class ServicePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string
  };

  state = {
    loading: true,
    id: '',
    data: {},
    merchant: '',
    branches: [],
    branchlist: [],
    isNewService: false
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);
    const queries = queryString.parse(this.props.location.search);

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.merchant, queries.id, queries.bid);
      } else {
        this.props.history.push('/login');
      }
    }, 600);

    this.submit = this.submit.bind(this);
    this.select = this.select.bind(this);
  }

  load = (mid, id, bid) => {
    this.setState({
      id: id,
      merchant: mid,
      branch_id: bid
    }, () => {
      if (id) {
        const xhr = createCORSRequest('POST', 'https://m9ucxo5cv2.execute-api.ap-southeast-1.amazonaws.com/v1/service/detail');
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('x-api-key', Config.API_KEY);
        xhr.setRequestHeader('Authorization', this.props.config.idToken);

        xhr.onload = () => {
          const data = JSON.parse(xhr.responseText);
          const body = data.body;
          console.log('bod', body);
          if (data.statusCode === 200) {
            this.setState({
              data: {
                name: body.service.name,
                id: this.state.id,
                display_url: body.service.display_url,
                description: body.service.description,
                any: body.service.allow_any_branch,
                public: body.service.allow_public,
                duration: body.service.duration,
                currency: body.service.currency,
                price: this.convertCurrencyFromDenom(body.service.currency, body.service.price)
              },
              currency: body.branch.currency,
              name: body.branch.merchant_name
            });
          } else {
            console.log('err', body.message);

            ButterToast.raise({
              content: <Cinnamon.Crunch 
                title="Error"
                content={body.message}
                scheme={Cinnamon.Crunch.SCHEME_RED} />
            });
          }
          this.setState({ loading: false });
        };
        
        xhr.send(JSON.stringify({
          id: id,
          branch: bid,
          merchant: mid
        }));
      } else {
        const xhr = createCORSRequest('POST', 'https://m9ucxo5cv2.execute-api.ap-southeast-1.amazonaws.com/v1/branch/detail');
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('x-api-key', Config.API_KEY);
        xhr.setRequestHeader('Authorization', this.props.config.idToken);
    
        xhr.onload = () => {
          const data = JSON.parse(xhr.responseText);
          const body = data.body;
          if (data.statusCode === 200) {
            this.setState({ 
              loading: false,
              data: {
                currency: body.branch.currency,
                any: false,
                public: false
              },
              isNewService: true,
              currency: body.branch.currency,
              name: body.branch.merchant_name
            });
          } else {
            console.log('err', body.message);

            ButterToast.raise({
              content: <Cinnamon.Crunch 
                title="Error"
                content={body.message}
                scheme={Cinnamon.Crunch.SCHEME_RED} />
            });
          }
          this.setState({ loading: false });
        };
    
        xhr.send(JSON.stringify({
          branch: this.state.branch_id,
          merchant: this.state.merchant
        }));
      }
    });
  }

  update = (params) => {
    console.log('update', params);

    const xhr = createCORSRequest('POST', 'https://m9ucxo5cv2.execute-api.ap-southeast-1.amazonaws.com/v1/service/update');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.props.history.push(`/pages/services?id=${this.state.branch_id}&merchant=${this.state.merchant}`);
      } else {
        console.log('err', body);
        this.setState({ loading: false });

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send(JSON.stringify(params));
  }

  createDisplay(param, file, key) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      let regex = '';
      if (file.type === 'application/pdf') {
        regex = /^data:application\/\w+;base64,/;
      } else {
        regex = /^data:image\/\w+;base64,/;
      }
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: `${key}.${file.type.split('/')[1]}`,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err1', err);

          ButterToast.raise({
            content: <Cinnamon.Crunch 
              title="Error"
              content={err.message}
              scheme={Cinnamon.Crunch.SCHEME_RED} />
          });
        } else {
          this.update(param);
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  convertCurrencyFromDenom = (curr, amount) => {
    switch (curr) {
      case 'SGD':
        return (amount / 100).toFixed(2).toString();
      default:
        return parseInt(amount, 10);
    }
    
  }

  convertCurrencyToDenom = (curr, amount) => {
    switch (curr) {
      case 'SGD':
        return parseInt(Math.floor(amount * 100), 10);
      default:
        return parseInt(amount, 10);
    }
    
  }

  submit = (e) => {
    console.log('e', e);

    this.setState({
      loading: true
    });

    if (e.display) {
      if (e.display.file.type === 'image/png' || e.display.file.type === 'image/jpg' || e.display.file.type === 'image/jpeg') {
        const key = `${this.state.name}/service/${new Date().getTime()}`;
        this.createDisplay({
          name: e.name,
          id: this.state.id ? this.state.id : 'none',
          description: e.description,
          display: `https://s3-ap-southeast-1.amazonaws.com/publicslozt/${key}.${e.display.file.type.split('/')[1]}`,
          any: e.any,
          public: e.public,
          duration: this.state.isNewService ? parseInt(e.durationSelect.value, 10) : e.duration,
          currency: e.currency,
          price: this.convertCurrencyToDenom(e.currency, parseInt(e.price, 10)),
          branch: this.state.branch_id,
          type: 'ADD'
        }, e.display.file, key);
      } else {
        this.setState({
          loading: false,
          error: 'Invalid file type'
        });
      }
    } else {
      this.update({
        name: e.name,
        id: this.state.id ? this.state.id : 'none',
        description: e.description,
        display: this.state.data.display_url ? this.state.data.display_url : 'none',
        any: e.any,
        public: e.public,
        duration: this.state.isNewService ? parseInt(e.durationSelect.value, 10) : e.duration,
        currency: e.currency,
        price: this.convertCurrencyToDenom(e.currency, parseInt(e.price, 10)),
        branch: this.state.branch_id,
        type: 'ADD'
      });
    }
  }

  select = (e) => {
    this.setState({ loading: true });
    this.update({
      name: this.state.data.name,
      id: this.state.id ? this.state.id : 'none',
      description: this.state.data.description,
      display: this.state.data.display_url ? this.state.data.display_url : 'none',
      any: this.state.data.any,
      public: this.state.data.public,
      duration: this.state.data.duration,
      currency: this.state.data.currency,
      price: this.state.data.price,
      branch: this.state.branch_id,
      type: 'DELETE'
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <ButterToast />
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <BranchForm isNew={this.state.isNewService} initialValues={this.state.data} onSubmit={this.submit} selectDelete={this.select} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(ServicePage);
