import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import LogInForm from './components/LogInForm';
import Logo from '../../shared/img/icon.png';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';

const ver = '1.4';

class LogIn extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    update: PropTypes.func
  };

  constructor() {
    super();
    this.submit = this.submit.bind(this);

    console.log('Admin', `Ver ${ver}`);

    try {
      [].filter((i) => { return i; });
    } catch (err) {
      window.location = '/obsl';
      this.state.error = true;
    }
  }

  state = {
    username: '',
    password: '',
    disabled: false,
    showError: false,
    error: '',
    loading: true
  };

  componentDidMount() {
    setTimeout(() => {
      console.log(`Version ${ver}`);
      if (this.props.config) {
        if (this.props.config.login_password && this.props.config.login_username) {
          this.test(this.props.config.login_username, this.props.config.login_password);
        } else {
          this.loaded();
        }
      } else {
        this.loaded();
      }
    }, 600);
  }

  loaded = () => {
    this.setState({
      loading: false
    });
  }

  submit = (e) => {
    this.setState({
      username: e.username,
      password: e.password,
      disabled: true,
      showError: false,
      error: ''
    }, () => {
      Auth.signIn(e.username, e.password)
        .then(user => {
          Auth.currentSession()
            .then((session) => {
              const config = {
                idToken: session.idToken.jwtToken,
                accessToken: session.accessToken.jwtToken,
                refreshToken: session.refreshToken.token,
                username: e.username,
                expiry: session.idToken.payload.exp * 1000,
                login_username: e.username,
                login_password: e.password
              };
              
              this.props.update(config);
  
              this.props.history.push('/pages/dashboard');
            });
        })
        .catch(err => {
          this.setState({
            disabled: false,
            showError: true,
            error: err.message
          });
        });
    });
  }

  test = (username, password) => {
    this.setState({ loading: false, disabled: true, showError: false, error: '' }, () => {
      this.submit({
        username: username,
        password: password
      });
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <div className="account">
          <p className="version">v{ver}</p>
          <div className="account__wrapper">
            <div className="account__card">
              <div>
                <img className="logo" src={Logo} alt="slozt-logo" />
              </div>
              <Alert color="danger" className="bold-text" isOpen={this.state.showError}>
                <div className="alert__content">
                  {this.state.error}
                </div>
              </Alert>
              <LogInForm onSubmit={this.submit} isDisabled={this.state.disabled} />
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default withRouter(LogIn);

