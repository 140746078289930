import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import InfoForm from './components/InfoForm';
import createCORSRequest from '../../shared/CreateCorsRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import renderSelectField from '../../shared/components/form/Select';

class MerchantPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
  };

  state = {
    loading: true,
    disabled: false,
    data: {},
    display_url: '',
    doc_url: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    this.state = {
      id: queries.id
    };
    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.id);
      } else {
        this.props.history.push('/login');
      }
    }, 1000);
  }

  load = (id) => {
    const xhr = createCORSRequest('POST', 'https://m9ucxo5cv2.execute-api.ap-southeast-1.amazonaws.com/v1/merchant');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    const params = {
      id: id
    };

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        const merchant = body.merchant;
        //merchant.code = `011805SLOZT${body.merchant.merchant_id}&type=${'shopscan'}`;
        merchant.timezoneString = `GMT ${merchant.timezone > 0 ? '+' : '-'}${merchant.timezone}:00`;
        merchant.submitted = new Date(merchant.created_at);
        merchant.categories = merchant.categories.map((cid) => {
          switch (cid) {
            case 1:
              return ' Fitness';
            case 2:
              return ' Wellness';
            case 3:
              return ' Beauty';
            default:
              return ' Unknown';
          }
        });
        this.setState({
          loading: false,
          data: merchant,
          display_url: body.merchant.display_url,
          doc_url: body.merchant.doc_url,
        });
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
      this.setState({ loading: false, disabled: false });
    };

    xhr.send(JSON.stringify(params));
  }

  enable = (e) => {
    //delete e.code;
    delete e.submitted;
    delete e.timezoneString;

    console.log('test', e.categories);

    e.categories = e.categories.map((cname) => {
      switch (cname.trim().toLowerCase()) {
        case 'fitness':
          return 1;
        case 'wellness':
          return 2;
        case 'beauty':
          return 3;
        default:
          return 0;
      }
    });

    e.currency = e.currency.trim().toUpperCase();

    const xhr = createCORSRequest('POST', 'https://m9ucxo5cv2.execute-api.ap-southeast-1.amazonaws.com/v1/merchant/confirm');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.props.history.push('/pages/dashboard');
      } else {
        this.setState({ loading: false });
        console.log('err', body);
        alert(body.message);
      }
    };

    xhr.send(JSON.stringify(e));
  }

  submit = (e) => {
    this.setState({
      loading: true,
      disabled: true
    }, () => {
      this.enable(e);
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <ButterToast />
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Merchant Information</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <InfoForm initialValues={this.state.data} code={this.state.data.code} onSubmit={this.submit} isDisabled={this.state.disabled} imgUrl={this.state.display_url} docUrl={this.state.doc_url} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(MerchantPage);
