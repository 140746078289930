import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import UserIcon from 'mdi-react/UserIcon';
import QRCode from 'qrcode-react';
import PropTypes from 'prop-types';

const parse = (value) => (value === undefined ? undefined : parseInt(value, 10));
const capitalize = value => value.toUpperCase();

const InfoForm = ({ handleChange, handleSubmit, value, isDisabled, imgUrl, docUrl, code }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="merchant_name"
          component="input"
          type="text"
          placeholder="Name"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Registration Number</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="reg_no"
          component="input"
          type="text"
          placeholder="Business Registration Number"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Country</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="country"
          component="input"
          type="text"
          placeholder="Country of Business"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Currency</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="currency"
          component="input"
          type="text"
          placeholder="Currency"
          disabled
          normalize={capitalize}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Timezone</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="timezoneString"
          component="input"
          type="text"
          placeholder="Timezone"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Merchant ID</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="merchant_id"
          component="input"
          type="text"
          placeholder="Slozt Merchant ID"
          disabled={true}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Submitted</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="submitted"
          component="input"
          type="text"
          placeholder="Submission Date"
          disabled={true}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Updated</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="updated"
          component="input"
          type="text"
          placeholder="Last Update Date"
          disabled={true}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Phone Number</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="phone"
          component="input"
          type="text"
          placeholder="Phone Number"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Email Address</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="email"
          component="input"
          type="text"
          placeholder="Email Address"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Address</span>
      <div className="form__form-group-field">
        <Field
          name="address"
          component="textarea"
          type="text"
          rows="10"
          placeholder="Address"
          disabled
        />
      </div>
    </div>
    <div className="form__form-group" hidden={true}>
      <span className="form__form-group-label">QR Code</span>
      <div className="form__form-group-field">
        <QRCode value={code} className="myqr-img" size={240} />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar" hidden>
      <Button color="primary" type="submit" disabled={isDisabled}>Update Changes</Button>
    </ButtonToolbar>
  </form>
);

InfoForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  docUrl: PropTypes.string,
  imgUrl: PropTypes.string,
  code: PropTypes.string
};
  
export default reduxForm({ form: 'infoform' })(InfoForm);
