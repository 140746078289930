import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Amplify from 'aws-amplify';
import { CognitoUserPool, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { Persist } from 'react-persist';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import Config from '../Config';

Amplify.configure({
  Auth: {
    region: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_T1qGozK2W',
    userPoolWebClientId: '7o4k0uudsf6pdnapv415oqcduf',
  }
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
      error: false
    };
    this.updateConfig = this.updateConfig.bind(this);
  }

  state = {
    user: {
      username: '',
      idToken: '',
      accessToken: '',
      refreshToken: '',
      expiry: 0,
      login_username: '',
      login_password: ''
    }
  };

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });

    setInterval(() => {
      this.refreshSession();
    }, this.refreshMinute * this.refreshSecond * 1000);
  }

  refreshMinute = 60;
  refreshSecond = 60;

  updateConfig(e) {
    this.setState({ user: e }, () => {
      console.log('refreshed session', this.state);
    });
  }

  refreshSession() {
    const userPool = new CognitoUserPool(Config.Pool);

    console.log('config state', this.state);
    const userData = {
      Username: this.state.user.username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const refreshToken = new CognitoRefreshToken({ RefreshToken: this.state.user.refreshToken });
    cognitoUser.refreshSession(refreshToken, (err, result) => {
      if (err) {
        console.log('refresh error', err);
        window.location = '/login';
      } else {
        const config = {
          idToken: result.idToken.jwtToken,
          accessToken: result.getAccessToken().getJwtToken(),
          refreshToken: result.getRefreshToken().getToken(),
          username: this.state.user.username,
          expiry: result.idToken.payload.exp * 1000,
          login_username: this.state.login_username,
          login_password: this.state.login_password
        };

        this.setState({
          user: config
        }, () => {
          console.log(`refreshed at ${new Date()}`, config, result);
        });
      }
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            {!loaded &&
            <div className={`load${loading ? '' : ' loaded'}`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
            }
            <div>
              <Router config={this.state.user} update={this.updateConfig} />
            </div>
            <Persist 
              name="sloztadmin" 
              data={{
                user: this.state.user
              }} 
              debounce={100} 
              onMount={(data) => {
                console.log('persist', data);
                this.setState({
                  user: data.user
                }, () => {
                  console.log('persist-ok', 'refreshed data', this.state);
                });
              }}
            />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
