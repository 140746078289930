import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditTable from '../../../shared/components/EditableTable';
import Pagination from '../../../shared/components/Pagination';

const ViewFormatter = ({ value }) => (
  <Link className="badge badge-success" to={`/pages/view?id=${value}`}>View</Link>);

ViewFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class ListTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'registration',
        name: 'Biz ID',
        sortable: true,
        width: 80
      },
      {
        key: 'name',
        name: 'Merchant Name',
        sortable: true,
        width: 240
      },
      {
        key: 'email',
        name: 'Email Address',
        sortable: true,
        width: 180
      },
      {
        key: 'merchant_id',
        name: '',
        sortable: false,
        formatter: ViewFormatter
      },
    ];

    this.state = {
      pageOfItems: [],
    };
  }

  onChangePage = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }
  
  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Merchant list</h5>
              </div>
              <p>Show
                <select className="select-options" onChange={this.onSelectSize}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
                entries
              </p>
              <EditTable heads={this.heads} rows={this.props.data} />
              <Pagination items={this.props.data} onChangePage={this.onChangePage} pageSize={this.state.pageSize} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
