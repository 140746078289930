import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import PropTypes from 'prop-types';
import RegisterForm from './components/RegisterForm';
import Logo from '../../shared/img/icon.png';
import Config from '../Config';

class Register extends Component {
  static propTypes = {
    history: PropTypes.object
  };

  constructor() {
    super();
    this.submit = this.submit.bind(this);
  }

  state = {
    disabled: false,
    showError: false,
    error: ''
  };

  submit = (e) => {
    this.setState({ disabled: true, showError: false, error: '' });

    const attributeList = [];
    const userPool = new CognitoUserPool(Config.Pool);

    userPool.signUp(e.username, e.password, attributeList, null, (err, result) => {
      if (err) {
        this.setState({
          disabled: false,
          showError: true,
          error: err.message
        });
      } else {
        this.props.history.push('/confirm');
      }
    });
  }

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div>
              <img className="logo" src={Logo} alt="slozt-logo" />
            </div>
            <Alert color="danger" className="bold-text" isOpen={this.state.showError}>
              <div className="alert__content">
                {this.state.error}
              </div>
            </Alert>
            <RegisterForm onSubmit={this.submit} isDisabled={this.state.disabled} />
            <div className="account__have-account">
              <p>Already have an account? <Link to="/login">Login</Link></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Register);
