import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButterToast, { Cinnamon } from 'butter-toast';
import LoadingScreen from '../../shared/components/loading';
import ListTable from './components/ListTable';
import createCORSRequest from '../../shared/CreateCorsRequest';
import Config from '../Config';

class DashboardPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    history: PropTypes.object
  };

  state = {
    loading: true,
    data: []
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load();
      } else {
        this.props.history.push('/login');
      }
    }, 1000);
  }

  componentWillReceiveProps(props) {
    console.log('prop', props);
  }

  load() {
    console.log('conf', this.props);

    const xhr = createCORSRequest('POST', 'https://m9ucxo5cv2.execute-api.ap-southeast-1.amazonaws.com/v1/getunconfirmed');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          updated: true,
          data: body.list
        });
        console.log('done', body);
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
      this.setState({ loading: false });
    };

    xhr.send();
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <ButterToast />
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Unconfirmed Merchants</h3>
            </Col>
          </Row>
          <Row hidden={this.state.data.length > 0}>
            <Col md={12}>
              No merchants found
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ListTable data={this.state.data} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(DashboardPage);
