/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import QRCode from 'qrcode-react';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const BranchForm = ({ handleChange, handleSubmit, value, code, services }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Branch Name</span>
      <div className="form__form-group-field">
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Branch Name"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Address</span>
      <div className="form__form-group-field">
        <Field
          name="address"
          component="input"
          type="text"
          placeholder="Branch Address"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Phone Number</span>
      <div className="form__form-group-field">
        <Field
          name="phone"
          component="input"
          type="text"
          placeholder="Branch Phone Number"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Branch Account Email</span>
      <div className="form__form-group-field">
        <Field
          name="email"
          component="input"
          type="text"
          placeholder="Branch Account Login"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Opening Hours</span>
      <div className="form__form-group-field">
        <Field
          name="opening"
          component="input"
          type="text"
          placeholder="Opening Hours"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Country</span>
      <div className="form__form-group-field">
        <Field
          name="country"
          component="input"
          type="text"
          placeholder="Country"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Currency</span>
      <div className="form__form-group-field">
        <Field
          name="currency"
          component="input"
          type="text"
          placeholder="Currency"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Timezone</span>
      <div className="form__form-group-field">
        <Field
          name="timezoneString"
          component="input"
          type="text"
          placeholder="Timezone"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Categories</span>
      <div className="form__form-group-field">
        <Field
          name="category_fitness"
          component={renderCheckBoxField}
          label="Fitness"
          className="colored-click"
        />
      </div>
      <div className="form__form-group-field">
        <Field
          name="category_wellness"
          component={renderCheckBoxField}
          label="Wellness"
          className="colored-click"
        />
      </div>
      <div className="form__form-group-field">
        <Field
          name="category_beauty"
          component={renderCheckBoxField}
          label="Beauty"
          className="colored-click"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Logo/Photo (optional)</span>
      <div className="form__form-group-field">
        <Field
          name="display"
          component={renderFileInputField}
        />
      </div>
    </div>
    <div className="form__form-group" hidden={false}>
      <span className="form__form-group-label">QR Code</span>
      <div className="form__form-group-field">
        <QRCode value={code} className="myqr-img" size={240} />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="success" type="button" onClick={services}>Services</Button>
      <Button color="primary" type="submit">Save</Button>
    </ButtonToolbar>
  </form>
);

BranchForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  code: PropTypes.string,
  services: PropTypes.func
};

export default reduxForm({ form: 'branchform' })(BranchForm);
