import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Register from '../Register/index';
import ConfirmationCard from '../Confirmation';
import DashboardPage from '../Dashboard/index';
import MerchantPage from '../Merchant';
import MerchantsPage from '../Merchants';
import MerchantDetailPage from '../MerchantDetail';
import ObsCard from '../Obsolete';
import BranchPage from '../Branch';
import BranchServicesPage from '../Services';
import ServicePage from '../Service';

class Pages extends Component {
  render() {
    return (
      <Switch>
        <Route path="/pages/dashboard" render={(routeProps) => <DashboardPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/view" render={(routeProps) => <MerchantPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/merchants" render={(routeProps) => <MerchantsPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/merchant" render={(routeProps) => <MerchantDetailPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/branch" render={(routeProps) => <BranchPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/services" render={(routeProps) => <BranchServicesPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/service" render={(routeProps) => <ServicePage config={this.props.config} location={routeProps.location} />} />
      </Switch>
    );
  }
}

Pages.propTypes = {
  config: PropTypes.object
};

class WrappedRoutes extends Component {
  render() {
    return (
      <div>
        <Layout />
        <div className="container__wrap">
          <Route path="/pages" render={(routeProps) => <Pages config={this.props.config} location={routeProps.location} />} />
        </div>
      </div>
    );
  }
}

WrappedRoutes.propTypes = {
  config: PropTypes.object
};

class Router extends Component {
  render() {
    return (
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/" render={(routeProps) => <LogIn update={this.props.update} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/login" render={(routeProps) => <LogIn update={this.props.update} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/confirm" component={ConfirmationCard} />
            <Route exact path="/obsl" component={ObsCard} />
            <Route path="/" render={(routeProps) => <WrappedRoutes config={this.props.config} location={routeProps.location} />} />
          </Switch>
        </main>
      </MainWrapper>
    );
  }
}

Router.propTypes = {
  update: PropTypes.func,
  config: PropTypes.object
};

export default Router;
