/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderSelectField from '../../../shared/components/form/Select';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const requiredOption = value => value ? undefined : 'Please select an option';

class Items extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  };

  state = {
    showAdd: false
  }

  render() {
    return (
      <main className="all-round-pad">
        <h5 className="cursor bold-text" role="button" onClick={() => this.setState({ showAdd: !this.state.showAdd })}>Add Branch</h5>
        <br />
        <form hidden={!this.state.showAdd} className="form form--horizontal" onSubmit={this.props.handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Name</span>
            <div className="form__form-group-field">
              <Field
                name="name"
                component="input"
                type="text"
                placeholder="Branch Name"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Address</span>
            <div className="form__form-group-field">
              <Field
                name="address"
                component="input"
                type="text"
                placeholder="Branch Address"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Phone Number</span>
            <div className="form__form-group-field">
              <Field
                name="phone"
                component="input"
                type="text"
                placeholder="Branch Phone Number"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Opening Hours</span>
            <div className="form__form-group-field">
              <Field
                name="opening"
                component="input"
                type="text"
                placeholder="Opening Hours"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Email Address</span>
            <div className="form__form-group-field">
              <Field
                name="email"
                component="input"
                type="text"
                placeholder="Branch account login"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password (at least 8 characters)</span>
            <div className="form__form-group-field">
              <Field
                name="password"
                component="input"
                type="password"
                placeholder="Branch account password"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Logo/Photo (optional)</span>
            <div className="form__form-group-field">
              <Field
                name="display"
                component={renderFileInputField}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Categories</span>
            <div className="form__form-group-field">
              <Field
                name="category_fitness"
                component={renderCheckBoxField}
                label="Fitness"
                className="colored-click"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="category_wellness"
                component={renderCheckBoxField}
                label="Wellness"
                className="colored-click"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="category_beauty"
                component={renderCheckBoxField}
                label="Beauty"
                className="colored-click"
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">Add</Button>
          </ButtonToolbar>
        </form>
      </main>
    );
  }
}

export default reduxForm({
  form: 'add_branch_form',
})(Items);
