import React from 'react';
import { Card, CardBody, Container, Col, Row } from 'reactstrap';
import EmailIcon from 'mdi-react/EmailIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';

const ConfirmationCard = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <br />
        <Card>
          <CardBody>
            <div className="email-confirmation">
              <div className="email-confirmation__icon">
                <EmailIcon className="email-confirmation__mail" />
                <CheckboxMarkedCircleIcon className="email-confirmation__check" />
              </div>
              <h3 className="email-confirmation__title">Account has been created</h3>
              <p className="email-confirmation__sub">Contact the Developers to enable your account</p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default ConfirmationCard;
