import React, { Component } from 'react';
import AWS from 'aws-sdk';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import InfoForm from './components/InfoForm';
import createCORSRequest from '../../shared/CreateCorsRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import ListTable from './components/ListTable';
import Items from './components/Items';
import renderSelectField from '../../shared/components/form/Select';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

const s3 = new AWS.S3({ params: { Bucket: 'publicslozt' } });

class MerchantDetailPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
  };

  state = {
    loading: true,
    disabled: false,
    data: {},
    list: [],
    display_url: '',
    doc_url: '',
    mid: ''
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);
    const queries = queryString.parse(this.props.location.search);

    console.log('id', this.state.mid);

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.id);
      } else {
        this.props.history.push('/login');
      }
    }, 1000);
  }

  load = (id) => {
    const xhr = createCORSRequest('POST', 'https://m9ucxo5cv2.execute-api.ap-southeast-1.amazonaws.com/v1/merchant/detail');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    this.setState({
      id: id
    });

    const params = {
      id: id
    };

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        const merchant = body.merchant.filter((item) => {
          return item.branch_id === '0';
        });

        if (merchant.length > 0) {
          console.log('merc', merchant[0]);
          merchant[0].timezoneString = `GMT ${merchant[0].timezone > 0 ? '+' : '-'}${merchant[0].timezone}:00`;
          merchant[0].submitted = new Date(merchant[0].created_at);
          merchant[0].updated = new Date(merchant[0].updated_at);
          this.setState({
            loading: false,
            data: merchant[0],
            display_url: body.merchant[0].display_url,
            doc_url: body.merchant[0].doc_url,
          });
        } else {
          console.log('err', 'main merchant not found');
          ButterToast.raise({
            content: <Cinnamon.Crunch 
              title="Error"
              content="Merchant not found"
              scheme={Cinnamon.Crunch.SCHEME_RED} />
          });
        }

        this.setState({
          list: body.merchant.filter((item) => {
            return item.branch_id !== '0';
          }).map((item) => {
            const branch = item;
            branch.query_key = `${item.branch_id}&merchant=${item.merchant_id}`;
            return branch;
          })
        });
        
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
      this.setState({ loading: false, disabled: false });
    };

    xhr.send(JSON.stringify(params));
  }

  enable = (e) => {
    //
  }

  update = (params) => {
    const xhr = createCORSRequest('POST', 'https://m9ucxo5cv2.execute-api.ap-southeast-1.amazonaws.com/v1/branch/update');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        window.location = `/pages/merchant?id=${this.state.id}`;
      } else {
        console.log('err', body);
        this.setState({ loading: false });

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    console.log('p', params);

    xhr.send(JSON.stringify(params));
  }

  createDisplay(param, file, key) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      let regex = '';
      if (file.type === 'application/pdf') {
        regex = /^data:application\/\w+;base64,/;
      } else {
        regex = /^data:image\/\w+;base64,/;
      }
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: `${key}.${file.type.split('/')[1]}`,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err1', err);
        } else {
          this.update(param);
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  submit = (e) => {
    const cats = [];
    if (e.category_fitness) cats.push(1);
    if (e.category_wellness) cats.push(2);
    if (e.category_beauty) cats.push(3);

    this.setState({
      loading: true
    });

    const mid = this.state.id;

    console.log('test', mid, this.state);

    if (e.display) {
      if (e.display.file.type === 'image/png' || e.display.file.type === 'image/jpg' || e.display.file.type === 'image/jpeg') {
        const key = `${this.state.data.merchant_name}/branch/${new Date().getTime()}`;
        this.createDisplay({
          merchantid: mid,
          name: e.name,
          id: 'none',
          address: e.address,
          phone: e.phone,
          email: e.email,
          password: e.password,
          opening: e.opening,
          display: `https://s3-ap-southeast-1.amazonaws.com/publicslozt/${key}.${e.display.file.type.split('/')[1]}`,
          merchant: this.state.data.merchant_name,
          country: this.state.data.country,
          currency: this.state.data.currency,
          timezone: this.state.data.timezone,
          created: 0,
          categories: cats
        }, e.display.file, key);
      } else {
        console.log('fa');
        this.setState({
          loading: false,
          error: 'Invalid file type'
        });
      }
    } else {
      this.update({
        merchantid: mid,
        name: e.name,
        id: 'none',
        address: e.address,
        phone: e.phone,
        email: e.email,
        password: e.password,
        opening: e.opening,
        display: this.state.data.display_url,
        merchant: this.state.data.merchant_name,
        country: this.state.data.country,
        currency: this.state.data.currency,
        timezone: this.state.data.timezone,
        created: 0,
        categories: cats
      });
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <ButterToast />
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Merchant Information</h3>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <InfoForm initialValues={this.state.data} code={this.state.data.code} isDisabled={this.state.disabled} imgUrl={this.state.display_url} docUrl={this.state.doc_url} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
            <Col md={6} hidden={this.state.list.length > 0}>
              <Card>
                <CardBody>
                  <Row>
                    No branches found
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Items onSubmit={this.submit} />
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12}>
                  <ListTable data={this.state.list} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(MerchantDetailPage);
