import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditTable from '../../../shared/components/EditableTable';
import Pagination from '../../../shared/components/Pagination';

const ViewFormatter = ({ value }) => (
  <Link className="badge badge-success" to={`/pages/branch?id=${value}`}>View</Link>);

ViewFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class ListTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'branch_id',
        name: 'Branch ID',
        sortable: true
      },
      {
        key: 'branch_name',
        name: 'Merchant Name',
        sortable: true
      },
      {
        key: 'query_key',
        name: '',
        sortable: false,
        formatter: ViewFormatter
      },
    ];

    this.state = {
      pageOfItems: [],
      pageSize: 100
    };
  }

  onChangePage = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }
  
  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Branch list</h5>
              </div>
              <EditTable heads={this.heads} rows={this.props.data} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
